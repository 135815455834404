import _ from 'lodash';

export const advisorsToOptions = (advisors, user, useNonSupervisors = false) => {
  const me = { label: `[Me] ${user.first_name} ${user.last_name}`, value: user.advisor.id };
  if (_.isEmpty(advisors)) return [me];

  const temp = _.orderBy(
    advisors
      .filter(advisor => advisor.id !== user.advisor.id)
      .map(advisor => ({
        label: advisor.name ?? `${advisor.user?.first_name} ${advisor.user?.last_name}`,
        value: advisor.id
      })),
    ['label', 'value'],
    ['desc', 'asc', 'asc']
  );

  const isUserInList = advisors.some(a => a.id === user.advisor.id);

  if (!useNonSupervisors || isUserInList) temp.unshift(me);

  return temp;
};

export default { advisorsToOptions };
