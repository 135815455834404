export const FOLLOWEE = 'followee';
export const FOLLOWERS = 'followers';

export const DELETE_MODAL = 'delete-modal';
export const EDIT_MODAL = 'edit-modal';

export const DEFAULT_MODAL_STATE = {
  type: null,
  data: { supervised_advisors: [], supervised_teams: [] }
};
