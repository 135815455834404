import cn from 'classnames';
import Disclosure from 'components/disclosure';
import Link from 'components/utils/link';
import { PropTypes } from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import './styles.scss';

const TEAMS = 'teams';
const ACCESS_ENDORSEMENTS = 'access-endorsements';
const SUPERVISORS = 'supervisors';

const TABS = {
  [TEAMS]: {
    title: 'Teams',
    url: '/advisor/me/teams'
  },
  [ACCESS_ENDORSEMENTS]: {
    title: 'Access Endorsements',
    url: '/advisor/me/access-endorsements'
  },
  [SUPERVISORS]: {
    title: 'Supervisors',
    url: '/advisor/me/supervisors'
  }
};

const CollaborationHub = ({ children, location }) => (
  <div id="collaboration-hub">
    <div className="header">
      <h2>Collaboration Hub</h2>
      <span>
        Maximize your advisory network&apos;s potential by utilizing our collaboration tools.
        <br />- Utilize the &lsquo;Teams&rsquo; feature to group advisors for joint account and
        model management.
        <br />- Grant individual advisors full access through the &lsquo;Access Endorsements&rsquo;
        section to collaborate on others&apos; ecosystems.
      </span>
    </div>
    <ul className="tabs">
      {Object.entries(TABS).map(([key, { title, url }]) => (
        <li className={cn({ active: location.pathname.includes(key) })} key={key}>
          <Link className="btn-transparent" to={url}>
            {title}
          </Link>
        </li>
      ))}
    </ul>
    {children}
    <Disclosure />
  </div>
);

CollaborationHub.defaultProps = {
  children: null
};

CollaborationHub.propTypes = {
  children: PropTypes.element,
  location: PropTypes.object.isRequired
};

export default withRouter(CollaborationHub);
