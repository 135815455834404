// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.supervisors {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.supervisors .supervisors__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
}
.supervisors .supervisors__header h2 {
  font-size: 1.6rem;
  margin-bottom: 0;
}
.supervisors .supervisors__header .btn-add-supervisors {
  height: 35px;
  font-size: 0.9rem;
}
.supervisors .supervisors__header-actions {
  display: flex;
  gap: 0.8rem;
}
.supervisors .supervisors__header-actions .form-group.search {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/supervisors/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,sBAAA;AACJ;AACI;EACE,iBAAA;EACA,gBAAA;AACN;AAEI;EACE,YAAA;EACA,iBAAA;AAAN;AAIE;EACE,aAAA;EACA,WAAA;AAFJ;AAII;EACE,gBAAA;AAFN","sourcesContent":[".supervisors {\n  display: flex;\n  flex-direction: column;\n  margin-top: 2rem;\n\n  .supervisors__header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 0.75rem;\n\n    h2 {\n      font-size: 1.6rem;\n      margin-bottom: 0;\n    }\n\n    .btn-add-supervisors {\n      height: 35px;\n      font-size: 0.9rem;\n    }\n  }\n\n  .supervisors__header-actions {\n    display: flex;\n    gap: 0.8rem;\n\n    .form-group.search {\n      margin-bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
