// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#supervisors-delete-modal > .modal-dialog > .modal-content > .modal-body .actions {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  gap: 10px;
}
#supervisors-delete-modal > .modal-dialog > .modal-content > .modal-body .actions button {
  min-width: 120px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/supervisors/delete-modal/styles.scss"],"names":[],"mappings":"AAII;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;EACA,SAAA;AAHN;AAKM;EACE,gBAAA;AAHR","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#supervisors-delete-modal {\n  > .modal-dialog > .modal-content > .modal-body {\n    .actions {\n      display: flex;\n      justify-content: center;\n      margin-top: 2rem;\n      gap: 10px;\n\n      button {\n        min-width: 120px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
