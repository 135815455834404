import { Modal, ModalBody, ModalHeader } from 'components/modal';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const SupervisorsDeleteModal = ({ data, onConfirm, onHide, show }) => {
  const { user } = data;

  const onDeleteHandler = async () => {
    await onConfirm(
      data.id,
      {
        supervised_advisors: [],
        supervised_teams: []
      },
      `You have removed all supervision for ${user?.first_name} ${user?.last_name}.`
    );
    onHide();
  };

  return (
    <Modal id="supervisors-delete-modal" className="modal-sm" onHide={onHide} show={show}>
      <ModalHeader />
      <ModalBody>
        <p className="text-center">
          Are you sure you want to remove all supervision for{' '}
          <b>
            {user?.first_name} {user?.last_name}
          </b>
          ?
        </p>
        <div className="actions">
          <button type="button" className="btn btn-black" onClick={onHide}>
            Cancel
          </button>
          <button type="button" className="btn btn-primary" onClick={onDeleteHandler}>
            Delete
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

SupervisorsDeleteModal.propTypes = {
  data: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default SupervisorsDeleteModal;
