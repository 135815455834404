import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';
import columns from './columns';
import './styles.scss';

const SupervisorsList = ({ data, defaultPageSize, onOpenModal, querySearch }) => {
  const lowerQuery = querySearch.toLowerCase();

  const filteredData = data.filter(supervisor => {
    const advisorName = `${supervisor.user?.first_name || ''} ${
      supervisor.user?.last_name || ''
    }`.toLowerCase();

    const advisorMatches = advisorName.includes(lowerQuery);

    const supervisedAdvisors = (supervisor.supervised_advisors || [])
      .map(a => a?.name ?? `${a?.first_name || ''} ${a?.last_name || ''}`)
      .join(', ')
      .toLowerCase();

    const supervisedTeams = (supervisor.supervised_teams || [])
      .map(t => t?.name)
      .join(', ')
      .toLowerCase();

    return (
      advisorMatches ||
      supervisedAdvisors.includes(lowerQuery) ||
      supervisedTeams.includes(lowerQuery)
    );
  });

  return (
    <ReactTable
      columns={columns(onOpenModal)}
      data={filteredData}
      minRows={1}
      noDataText={<div style={{ height: 50 }}>No supervisors found.</div>}
      pageSize={defaultPageSize}
      resizable={false}
      showPageSizeOptions={false}
      showPagination
      sortable
    />
  );
};

SupervisorsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultPageSize: PropTypes.number.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  querySearch: PropTypes.string.isRequired
};

export default SupervisorsList;
