import React from 'react';

const columns = (onRemove, type = 'advisor') => [
  {
    id: `authorized-${type}`,
    Header: `Authorized ${type === 'team' ? 'Teams' : 'Advisors'}`,
    style: { justifyContent: 'flex-start', padding: '10px 10px' },
    headerStyle: { justifyContent: 'flex-start' },
    accessor: item => item.name
  },
  {
    id: `remove-${type}`,
    width: 100,
    Header: 'Remove',
    Cell: ({ original: { id } }) => (
      <button type="button" className="btn btn-remove-advisor" onClick={() => onRemove(id)}>
        <span className="icon-minus" />
        Remove
      </button>
    )
  }
];

export default columns;
