import _ from 'lodash';
import React from 'react';
import { DELETE_MODAL, EDIT_MODAL } from '../constants';
import './styles.scss';

const columnHeader = header => (
  <span>
    {header} <img src="/img/sorting.svg" className="sorting" alt="sorting" />
  </span>
);

const columns = onOpenModal => [
  {
    id: 'advisor',
    Header: columnHeader('Advisor'),
    accessor: data => `${data.user?.first_name || ''} ${data.user?.last_name || ''}`.trim(),
    style: { justifyContent: 'flex-start', padding: '10px 10px' },
    headerStyle: { justifyContent: 'flex-start' }
  },
  {
    id: 'teams',
    Header: columnHeader('Supervised Teams'),
    accessor: data =>
      _.isEmpty(data.supervised_teams)
        ? '-'
        : data.supervised_teams
            .map(team => team?.name)
            .filter(Boolean)
            .join(', '),
    style: { justifyContent: 'flex-start', padding: '10px 10px' },
    headerStyle: { justifyContent: 'flex-start' }
  },
  {
    id: 'supervised_advisors',
    Header: columnHeader('Supervised Advisors'),
    accessor: data =>
      _.isEmpty(data.supervised_advisors)
        ? '-'
        : data.supervised_advisors
            .map(a => a?.name ?? `${a?.first_name || ''} ${a?.last_name || ''}`.trim())
            .filter(Boolean)
            .join(', '),
    style: { justifyContent: 'flex-start', padding: '10px 10px' },
    headerStyle: { justifyContent: 'flex-start' }
  },
  {
    Header: 'Edit',
    id: 'edit',
    width: 70,
    Cell: ({ original }) => (
      <button
        type="button"
        aria-label="Edit supervisor"
        className="icon-pencil-line icon-access-endorsements-delete"
        onClick={() => onOpenModal(EDIT_MODAL, original)}
        title="Edit supervisor"
      />
    )
  },
  {
    Header: 'Delete',
    id: 'delete',
    width: 70,
    Cell: ({ original }) => (
      <button
        type="button"
        aria-label="Delete supervisor"
        className="fs-icon-trash icon-access-endorsements-delete"
        onClick={() => onOpenModal(DELETE_MODAL, original)}
        title="Delete supervisor"
      />
    )
  }
];

export default columns;
