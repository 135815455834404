/* eslint-disable default-param-last */
import {
  ADVISOR_CREATE,
  ADVISOR_DELETE,
  ADVISOR_DOWNLOADING_DATA,
  ADVISOR_DOWNLOADING_DATA_FINISHED,
  ADVISOR_EDIT,
  ADVISOR_GET,
  ADVISOR_LIST,
  ADVISOR_LIST_ES,
  ADVISOR_LIST_FOLLOWERS,
  ADVISOR_LIST_SUPERVISORS,
  ADVISOR_STATS_GET,
  SUPERVISOR_LIST_ES,
  NON_SUPERVISOR_LIST_ES,
  ADVISOR_VIEW,
  EXCEPTIONS_LIST_ES,
  EXCEPTIONS_TAGS
} from 'constants/actions';

const initialState = {
  active: [],
  downloadingData: false,
  esExceptions: [],
  esExceptionsMeta: {},
  exceptionTags: [],
  followers: [],
  supervisorsList: [],
  list: [],
  listMeta: {},
  stats: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADVISOR_LIST:
      return { ...state, list: action.data?.data || [], listMeta: action.data?.meta || {} };

    case ADVISOR_GET:
      return { ...state, view: action.data };

    case ADVISOR_CREATE:
      // not implemented
      return state;

    case ADVISOR_DELETE:
      // not implemented
      return state;

    case ADVISOR_VIEW:
      // not implemented
      return state;

    case ADVISOR_EDIT:
      return { ...state, edit: action.data };

    case ADVISOR_LIST_ES:
      return { ...state, esList: action.data };

    case ADVISOR_STATS_GET:
      return { ...state, stats: action.data };

    case SUPERVISOR_LIST_ES:
      return { ...state, supervisors: action.data };

    case NON_SUPERVISOR_LIST_ES:
      return { ...state, nonSupervisors: action.data };

    case ADVISOR_DOWNLOADING_DATA:
      return { ...state, downloadingData: true };

    case ADVISOR_DOWNLOADING_DATA_FINISHED:
      return { ...state, downloadingData: false };

    case ADVISOR_LIST_FOLLOWERS:
      return { ...state, followers: action.data };

    case ADVISOR_LIST_SUPERVISORS:
      return { ...state, supervisorsList: action.data };

    case EXCEPTIONS_TAGS:
      return { ...state, exceptionTags: action.data };

    case EXCEPTIONS_LIST_ES:
      return { ...state, esExceptions: action.exceptions, esExceptionsMeta: action.meta };

    default:
      return state;
  }
};
