import Select from 'components/select';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { teamsToOptions } from './utils';

const SelectTeam = ({ teamList, onSelect, customOptions, ...rest }) => {
  const handleChange = option => {
    const team = teamList.find(team => team.id === option?.value);
    onSelect(option, team);
  };

  const options = useMemo(() => teamsToOptions(teamList), [teamList]);

  return (
    <Select
      onChange={handleChange}
      options={customOptions ? customOptions(options) || options : options}
      customOptions={customOptions}
      {...rest}
    />
  );
};

SelectTeam.propTypes = {
  customOptions: PropTypes.func,
  onSelect: PropTypes.func,
  name: PropTypes.string,
  teamList: PropTypes.array
};

SelectTeam.defaultProps = {
  customOptions: null,
  onSelect: () => {},
  name: '',
  teamList: []
};

export default connect(state => ({
  teamList: state.teams.list
}))(SelectTeam);
