import _ from 'lodash';

export const teamsToOptions = teams => {
  if (_.isEmpty(teams)) return [];

  return _.orderBy(
    teams.map(team => ({
      label: team.name,
      value: team.id
    })),
    ['label'],
    ['asc']
  );
};

export default { teamsToOptions };
