import { preferredValuesPerPage } from 'constants/pagination';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import CollaborationPagination from 'pages/collaboration-hub/pagination';
import CollaborationSearch from 'pages/collaboration-hub/search';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DEFAULT_MODAL_STATE, DELETE_MODAL, EDIT_MODAL } from './constants';
import SupervisorsDeleteModal from './delete-modal';
import SupervisorsEditModal from './edit-modal';
import SupervisorsList from './list';
import './styles.scss';

const Supervisors = ({ supervisorsList, advisors, teamList }) => {
  const { advisorProvider, teamProvider } = useContext(AdvisorContext);
  const [modal, setModal] = useState(_.cloneDeep(DEFAULT_MODAL_STATE));
  const [pageSize, setPageSize] = useState(preferredValuesPerPage().value);
  const [querySearch, setQuerySearch] = useState('');
  const [supervisorCompleteData, setSupervisorCompleteData] = useState([]);

  const fetchSupervisors = () => {
    advisorProvider.listSupervisors();
    advisorProvider.es.list({ get_all_advisors: true });
    teamProvider.getList();
  };

  const onEditSupervisor = (id, payload, message) => {
    advisorProvider
      .editSupervisor(id, payload)
      .then(() => {
        fetchSupervisors();
        toast.success(message);
      })
      .catch(() => toast.error('Error updating supervisor.'));
  };

  const onOpenModal = (type, data) => {
    setModal({ type, data });
  };

  const onCloseModal = () => {
    setModal(_.cloneDeep(DEFAULT_MODAL_STATE));
  };

  useEffect(() => {
    fetchSupervisors();
  }, []);

  const buildSupervisorCompleteData = (supervisorsList, advisors, teamList) => {
    const advisorMap = _.keyBy(advisors, 'id');
    const teamMap = _.keyBy(teamList, 'id');

    return supervisorsList.map(supervisor => ({
      ...supervisor,
      supervised_advisors: (supervisor.supervised_advisors || [])
        .map(advisorId => advisorMap[advisorId])
        .filter(Boolean),
      supervised_teams: (supervisor.supervised_teams || [])
        .map(teamId => teamMap[teamId])
        .filter(Boolean)
    }));
  };

  useEffect(() => {
    const enrichedData = buildSupervisorCompleteData(supervisorsList, advisors, teamList);
    setSupervisorCompleteData(enrichedData);
  }, [supervisorsList, advisors, teamList]);

  return (
    <div className="supervisors">
      <div className="supervisors__header">
        <h2>Supervisors</h2>
        <div className="supervisors__header-actions">
          <CollaborationSearch searchCallback={setQuerySearch} />
          <CollaborationPagination paginationCallback={setPageSize} />
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => onOpenModal(EDIT_MODAL, _.cloneDeep(DEFAULT_MODAL_STATE.data))}
          >
            Add Supervisor
          </button>
        </div>
      </div>

      <div className="supervisors__content">
        <SupervisorsList
          data={supervisorCompleteData}
          defaultPageSize={pageSize}
          onOpenModal={onOpenModal}
          querySearch={querySearch}
        />
      </div>

      <SupervisorsEditModal
        data={modal.data}
        onConfirm={onEditSupervisor}
        onHide={onCloseModal}
        show={modal.type === EDIT_MODAL}
      />
      <SupervisorsDeleteModal
        data={modal.data}
        onConfirm={onEditSupervisor}
        onHide={onCloseModal}
        show={modal.type === DELETE_MODAL}
      />
    </div>
  );
};

Supervisors.propTypes = {
  advisors: PropTypes.array,
  supervisorsList: PropTypes.array,
  teamList: PropTypes.array
};

Supervisors.defaultProps = {
  advisors: [],
  supervisorsList: [],
  teamList: []
};

export default connect(state => ({
  advisors: state.advisors.esList,
  supervisorsList: state.advisors.supervisorsList,
  teamList: state.teams.list
}))(Supervisors);
