import { Modal, ModalBody, ModalHeader } from 'components/modal';
import _ from 'lodash';
import CollaborationSelect from 'pages/collaboration-hub/select';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import SelectAdvisor from 'components/advisor/SelectAdvisor';
import TeamSelect from '../select';
import columns from './columns';
import './styles.scss';

const normalizeAdvisors = advisors =>
  advisors.map(advisor => ({
    id: advisor.id,
    name: advisor.name
  }));

const SupervisorsEditModal = ({ data, onConfirm, onHide, show }) => {
  const [supervisor, setSupervisor] = useState(data || {});
  const [advisors, setAdvisors] = useState(normalizeAdvisors(data.supervised_advisors || []));
  const [teams, setTeams] = useState(data.supervised_teams || []);

  const isCreating = _.isEmpty(data.user);
  const isSubmitDisabled =
    _.isEmpty(supervisor) || (_.isEmpty(advisors) && _.isEmpty(teams) && isCreating);
  const selectedAdvisorIds = advisors.map(a => a.id);
  const selectedOptions = supervisor?.id
    ? [...selectedAdvisorIds, supervisor.id]
    : selectedAdvisorIds;
  const selectedOptionsTeam = teams.map(t => t.id);

  const onSelectSupervisor = option => {
    if (option) setSupervisor({ id: option.value, name: option.label });
    else setSupervisor({});
  };

  const onRemoveAdvisor = advisorId => {
    setAdvisors(advisors.filter(advisor => advisor.id !== advisorId));
  };

  const onAddAdvisor = option => {
    setAdvisors(prev => [...prev, { id: option.value, name: option.label }]);
  };

  const onRemoveTeam = teamId => {
    setTeams(teams.filter(team => team.id !== teamId));
  };

  const onAddTeam = option => {
    setTeams(prev => [...prev, { id: option.value, name: option.label }]);
  };

  const onSaveHandler = async () => {
    await onConfirm(
      supervisor.id,
      {
        supervised_advisors: advisors.map(a => a.id),
        supervised_teams: teams.map(t => t.id)
      },
      `You have successfully ${isCreating ? 'created' : 'updated'} the supervisor ${
        isCreating ? supervisor.name : data.user?.first_name || ''
      } ${data.user?.last_name || ''}.`
    );
    onHide();
  };

  useEffect(() => {
    setSupervisor(data || {});
    setAdvisors(normalizeAdvisors(data.supervised_advisors || []));
    setTeams(data.supervised_teams || []);
  }, [data]);

  return (
    <Modal id="supervisors-edit-modal" className="modal-lg" onHide={onHide} show={show}>
      <ModalHeader />
      <ModalBody>
        <div className="text-left mb-4">
          <h4>{isCreating ? 'Create' : 'Edit'} Supervisor</h4>
        </div>
        <div className="supervisors-edit">
          <div className="supervisors-edit__detail">
            <label htmlFor="supervisor">
              <b className="d-block">Supervisor</b>
              {isCreating ? (
                <SelectAdvisor
                  isClearable
                  name="supervisor"
                  onSelect={onSelectSupervisor}
                  useSearchIcon={false}
                  useNonSupervisors
                />
              ) : (
                <span className="h6">
                  {data.user?.first_name} {data.user?.last_name || ''}{' '}
                </span>
              )}
            </label>

            <label htmlFor="advisors">
              <b>Select Advisors</b>
              <CollaborationSelect onAdd={onAddAdvisor} selectedOptions={selectedOptions} />
            </label>
          </div>

          <div className="supervisors-edit__advisors">
            <ReactTable
              minRows={1}
              columns={columns(onRemoveAdvisor, 'advisor')}
              data={advisors}
              noDataText={<div style={{ marginTop: 50 }}>No advisors found.</div>}
              showPagination={false}
              sortable={false}
            />
          </div>

          <hr className="tables-line-separator" />

          <div className="supervisors-edit__detail">
            <label htmlFor="advisors">
              <b>Select Teams</b>
              <TeamSelect onAdd={onAddTeam} selectedOptions={selectedOptionsTeam} />
            </label>
          </div>

          <div className="supervisors-edit__advisors">
            <ReactTable
              minRows={1}
              columns={columns(onRemoveTeam, 'team')}
              data={teams}
              noDataText={<div style={{ marginTop: 50 }}>No Teams found.</div>}
              showPagination={false}
              sortable={false}
            />
          </div>

          <div className="text-right supervisors-edit__buttons">
            <button type="button" className="btn btn-black mr-2" onClick={onHide}>
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              disabled={isSubmitDisabled}
              onClick={onSaveHandler}
            >
              Save
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

SupervisorsEditModal.propTypes = {
  data: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default SupervisorsEditModal;
