import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SelectTeam from './select-team';
import './styles.scss';

const ENTER_KEY = 'Enter';

const TeamSelect = ({ onAdd, selectedOptions }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionDetails, setSelectedOptionDetails] = useState(null);

  const onAddHandler = () => {
    onAdd(selectedOption, selectedOptionDetails);
    setSelectedOption(null);
    setSelectedOptionDetails(null);
  };

  const onSetSelectedOption = (option, details) => {
    setSelectedOption(option);
    setSelectedOptionDetails(details);
  };

  const onlyUnselectedOptions = options => {
    if (_.isEmpty(selectedOptions)) return options;
    return options.filter(option => !selectedOptions.includes(option?.value));
  };

  return (
    <div className="collaboration-member">
      <SelectTeam
        customOptions={onlyUnselectedOptions}
        isClearable
        name="team-select"
        onKeyDown={event => {
          if (selectedOption && event.key === ENTER_KEY) onAddHandler();
        }}
        onSelect={onSetSelectedOption}
        useSearchIcon={false}
        value={selectedOption}
      />
      <button
        className="btn btn-primary"
        disabled={!selectedOption}
        onClick={onAddHandler}
        type="button"
      >
        Add
      </button>
    </div>
  );
};

TeamSelect.defaultProps = {
  onAdd: () => {},
  selectedOptions: []
};

TeamSelect.propTypes = {
  onAdd: PropTypes.func,
  selectedOptions: PropTypes.array
};

export default React.memo(TeamSelect);
