import Select from 'components/select';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { advisorsToOptions } from './utils';

const SelectAdvisor = ({
  advisors,
  onSelect,
  customOptions,
  useNonSupervisors,
  nonSupervisors,
  ...rest
}) => {
  const { advisorProvider, user } = useContext(AdvisorContext);

  const dataSource = useNonSupervisors ? nonSupervisors : advisors;

  const handleChange = option => {
    const advisor = dataSource.find(advisor => advisor.id === option?.value);
    onSelect(option, advisor);
  };

  const options = useMemo(
    () => advisorsToOptions(dataSource, user, useNonSupervisors),
    [JSON.stringify(dataSource), user.advisor.id]
  );

  useEffect(() => {
    if (useNonSupervisors) advisorProvider.es.NonSupervisorList();
    else advisorProvider.es.list({ get_all_advisors: true });
  }, [useNonSupervisors]);

  if (!dataSource) return null;

  return (
    <Select
      onChange={handleChange}
      options={customOptions ? customOptions(options) || options : options}
      customOptions={customOptions}
      {...rest}
    />
  );
};

SelectAdvisor.propTypes = {
  advisors: PropTypes.arrayOf(PropTypes.object),
  nonSupervisors: PropTypes.arrayOf(PropTypes.object),
  useNonSupervisors: PropTypes.bool,
  className: PropTypes.string,
  customOptions: PropTypes.func,
  onSelect: PropTypes.func,
  name: PropTypes.string
};

SelectAdvisor.defaultProps = {
  advisors: [],
  nonSupervisors: [],
  useNonSupervisors: false,
  className: '',
  customOptions: null,
  onSelect: () => {},
  name: ''
};

export default connect(state => ({
  advisors: state.advisors.esList,
  nonSupervisors: state.advisors.nonSupervisors
}))(SelectAdvisor);
